import React, { useEffect, useState } from 'react';
import { S3_IMAGES_URL } from 'util/constants';
import studioLoaderStyles from '../../../pages/user/studio.module.css';
import styles from './styles.module.css';

export default function TopLevelLoader({ children }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <>
      <main
        className={styles.loaderWrapper}
        style={visible ? { display: 'none' } : {}}
      >
        <img
          src={`${S3_IMAGES_URL}/assets/logos/ozoneStudioLogo.png`}
          className={studioLoaderStyles.spin}
          alt='spin'
        />
        <h3 className={styles.studioLoaderStyles}>Loading...</h3>
      </main>
      <main
        className={styles.mainWrapper}
        style={{ visibility: visible ? 'visible' : 'hidden' }}
      >
        {children}
      </main>
    </>
  );
}
