import { configureStore } from '@reduxjs/toolkit';
import paymentStatusReducer from '@src/features/shopping-cart/paymentStatusSlice';

export const store = configureStore({
  reducer: {
    paymentStatus: paymentStatusReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
