const DEFAULT_PREVIEW = {
  image:
    'https://d3to9p4w6lww5g.cloudfront.net/preview-sites/studio-preview.jpg',
  pageTitle: 'Ozone Studio',
  favicon: '/logo.jpeg',
  ogTitle: 'Ozone Studio Platform',
  ogDesc:
    'Build, design, and share web based virtual worlds like never before. Powered by Ozone platform. Multi blockchain interoperability built-in.',
};

const ZEPETO_PREVIEW = {
  image:
    'https://d3to9p4w6lww5g.cloudfront.net/preview-sites/zepeto-preview.jpg',
  pageTitle: 'Zepeto Web Studio',
  favicon: 'https://cf-web.zepeto.me/favicon.png',
  ogTitle: 'ZEPETO WEB STUDIO',
  ogDesc:
    'A brand new way to build Zepeto worlds in the cloud powered by groundbreaking 3D technology and the new 3D Model Standard GLTF.  Build on the Web. Save as Unity. Deploy to Zepeto in minutes.',
};

export const SITE_PREVIEW = {
  DEFAULT: DEFAULT_PREVIEW,
  ZEPETO: ZEPETO_PREVIEW,
};
