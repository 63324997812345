import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

interface PaymentStatus {
  status: boolean;
}

const initialState: PaymentStatus = {
  status: false,
};

export const paymentStatusSlice = createSlice({
  name: 'paymentStatus',
  initialState,
  reducers: {
    confirm: (state) => {
      state.status = true;
    },
    deny: (state) => {
      state.status = false;
    },
  },
});

export const { confirm, deny } = paymentStatusSlice.actions;
export const selectCount = (state: RootState) => state.paymentStatus.status;
export default paymentStatusSlice.reducer;
