import { setContext } from '@apollo/client/link/context';
import { ServerError } from '@apollo/client/link/utils';
import { onError } from '@apollo/client/link/error';

// cached storage for the user token
let cachedToken;

const withToken = setContext(async (operation, { headers }) => {
  // if you have a cached value, return it immediately
  if (cachedToken) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${cachedToken}`,
      },
    };
  }
  let token;

  try {
    const res = await fetch(`/api/auth/token`).then((res) => res.json());
    token = res.token;
  } catch (e) {
    console.error(e);
  }

  if (!token) {
    return null;
  }

  cachedToken = token;
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const resetToken = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.name === 'ServerError' &&
    (networkError as ServerError).statusCode === 401
  ) {
    // remove cached token on 401 from the server
    cachedToken = null;
  }
});

export const authApolloLink = withToken.concat(resetToken);
